/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cloud_logic_custom": [
        {
            "name": "gravityformsapi",
            "endpoint": "https://bckm0rwy8f.execute-api.eu-north-1.amazonaws.com/production",
            "region": "eu-north-1"
        },
        {
            "name": "stripeapi",
            "endpoint": "https://wt4kax86qd.execute-api.eu-north-1.amazonaws.com/production",
            "region": "eu-north-1"
        },
        {
            "name": "webinarapi",
            "endpoint": "https://0kqusp267f.execute-api.eu-north-1.amazonaws.com/production",
            "region": "eu-north-1"
        }
    ]
};


export default awsmobile;
